import React, { useEffect, useState, useMemo } from "react";
import { Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import app from "../../../../../app/initialize";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const uiService = useHtmlClassService();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    const messaging = getMessaging(app);

    const requestPermission = () => {
      console.log("Requesting permission...");
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          }
        });
      } else {
        console.error("Notification API not supported.");
      }
    };

    const calculateTimeElapsed = (timestamp) => {
      const messageTime = new Date(timestamp);
      const now = new Date();
      const differenceInMillis = now - messageTime;
      const differenceInSeconds = Math.floor(differenceInMillis / 1000);
      if (differenceInSeconds < 60) {
        return "just now";
      } else if (differenceInSeconds < 3600) {
        const minutes = Math.floor(differenceInSeconds / 60);
        return `${minutes} min ago`;
      } else if (differenceInSeconds < 86400) {
        const hours = Math.floor(differenceInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else {
        const days = Math.floor(differenceInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
      }
    }

    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VITE_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          axios.post(`${process.env.REACT_APP_API_URL}user/setToken`, {
            fcm_token: currentToken
          })
            .then(response => {
              console.log('FCM Token successfully updated');
            })
            .catch(error => {
              console.error('Error updating FCM Token:', error);
            });
        } else {
          console.log("No registration token available. Request permission to generate one.");
          requestPermission();
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    const unsubscribe = onMessage(messaging, function ({ notification, data }) {
      const notificationTime = data.timestamp;
      const timeElapsed = calculateTimeElapsed(notificationTime);
      const notificationWithTime = {
        ...notification,
        path: data.path,
        time: timeElapsed,
      };
      setNotifications((prevNotifications) => [...prevNotifications, notificationWithTime]);
      setUnreadCount((prevCount) => prevCount + 1);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}user/user/notifications`)
      .then((response) => {
        const userNotifications = response?.data;
        userNotifications.forEach(notification => {
          const notificationData = JSON.parse(notification.notification_message);
          const notificationWithTime = {
            ...notificationData,
            receivedAt: new Date(),
          };
          setNotifications(prevNotifications => [...prevNotifications, notificationWithTime]);
          setUnreadCount(prevCount => prevCount + 1);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  {unreadCount > 0 && (
                    <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                      {unreadCount} New
                    </span>
                  )}
                </h4>
                <Tab.Container defaultActiveKey={"Alerts"}>
                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {notifications.length > 0 ? (
                          notifications.map((notification, index) => (
                            <div key={index} className="d-flex align-items-center mb-6">
                              <div className="symbol symbol-40 symbol-light-warning mr-5">
                                <span className="symbol-label">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                    className="svg-icon-lg svg-icon-warning"
                                  ></SVG>
                                </span>
                              </div>
                              <div className="d-flex flex-column font-weight-bold">
                                <a
                                  href={process.env.PUBLIC_URL + notification.path}
                                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                                >
                                  {notification.title}
                                </a>
                                <span className="text-muted">
                                  {notification.body}
                                </span>
                                <div className="text-muted">{notification.time}</div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                          </Tab.Pane>
                        )}
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}