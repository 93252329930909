import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { DashboardMaster } from "./DashboardMaster";
import axios from "axios";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const endpoints = ["dashboard", "dashboard/ppm"];

export function Dashboard() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [helpdeskcount, setHelpdeskcount] = useState([]);
  const [error, setError] = useState(null);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    setHelpdeskcount([]);
    axios.get(`${process.env.REACT_APP_API_URL}${endpoints[value]}`)
      .then(response => {
        setHelpdeskcount(response.data.helpdesk);
      })
      .catch(error => {
        console.error(error);
        setError('Failed to fetch data');
        setHelpdeskcount([]);
      });
  }, [value]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Helpdesk" />
          <Tab label="PPM" />
        </Tabs>
      </AppBar>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {value === 0 && (
        <TabContainer>
          <DashboardMaster helpdesk={helpdeskcount} textName="Helpdesk" />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <DashboardMaster helpdesk={helpdeskcount} textName="Preventive Maintenance" />
        </TabContainer>
      )}
    </div>
  );
}