import React from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';

export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
];

export const initialFilter = {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 5
};

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRatingTooltip = (rating_star) => {
    switch (rating_star) {
        case 1: return "Bad";
        case 2: return "OK";
        case 3: return "Good";
        case 4: return "Very Good";
        case 5: return "Excellent";
        default: return "";
    }
};

export const formatPercentage = (value, total) => `${Math.min(Math.round((value / total) * 100), 100)}%`;

export const formatDate = (date) => moment(date).format('DD-MMM-YYYY h:mm:ss');

export const getRatingStars = (row) => {
    const stars = [];
    for (let i = 0; i < row.rating_star; i++) {
        stars.push(
            <OverlayTrigger
                key={i}
                placement="top"
                overlay={<Tooltip id={`tooltip-star-${i}`}>{getRatingTooltip(i + 1)}</Tooltip>}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="gold"
                    stroke="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ marginRight: "5px" }}
                >
                    <path
                        d="M12 2 L15.09 8.26 L22 9.27 L17 14.14 L18.18 21.02 L12 17.77 L5.82 21.02 L7 14.14 L2 9.27 L8.91 8.26 L12 2"
                        fill="gold"
                    />
                </svg>
            </OverlayTrigger>
        );
    }
    return stars;
};

export const currentDate = new Date();

export const formattedDateTime =
    `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;

export const formattedDate =
    `${currentDate.getFullYear()}-${currentDate.toLocaleString("en-US", { month: '2-digit' })}-${currentDate.toLocaleString("en-US", { day: '2-digit' })}`;


const endDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

export const formattedEndDate =
    `${endDate.getFullYear()}-${endDate.toLocaleString("en-US", { month: '2-digit' })}-${endDate.toLocaleString("en-US", { day: '2-digit' })}`;

export const StatusCssClasses = ["success", "danger", "info", "primary", "dark", "secondary", "info", "success"];

export const StatusTitles = ["", "Request Raised", "Material Issued", "LPO Completed", "GRN Completed", "DN Completed", "Invoice completed", "Completed"];

export const StatusColumnFormatter = (row, status) => (
    <Badge variant={StatusCssClasses[status ? status : 0]}>
        {status ? StatusTitles[status] : row.status}
    </Badge>
);