import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive, useRolesAndPermissions, hasModulePermission, hasFormPermission } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { permissions, roles } = useRolesAndPermissions();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu ? "menu-item-active" : ""} menu-item-open menu-item-not-highlighted`
      : "";
  };

  const renderMenuItem = (module, form, url, text) => {
    return hasFormPermission(roles, permissions, module, form) ? (
      <li className={`menu-item ${getMenuItemActive(url)}`} aria-haspopup="true">
        <NavLink className="menu-link" to={url}>
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{text}</span>
        </NavLink>
      </li>
    ) : null;
  };

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {hasModulePermission(roles, permissions, "Dashboard") && (
        <li className={`menu-item ${getMenuItemActive("/dashboard")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Administration") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">Administration</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Administration", "Users", "/user", "Users")}
              {renderMenuItem("Administration", "UserPermissions", "/userPermission", "User Permission")}
              {renderMenuItem("Administration", "Organizations", "/organization", "Organization")}
              {renderMenuItem("Administration", "UserTypes", "/userType", "User Type")}
              {renderMenuItem("Administration", "UserLogs", "/userLog", "User Log")}
              {renderMenuItem("Administration", "Branding", "/branding", "Branding")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Call Centre") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-1.svg")} />
            </span>
            <span className="menu-text">Call Centre</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Call Centre", "Tasks", "/task", "Create Task")}
              {renderMenuItem("Call Centre", "Assignments", "/assignment", "Work Assignment")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Data Configuration") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Polygon.svg")} />
            </span>
            <span className="menu-text">Data Configuration</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Data Configuration", "Settings", "/settings", "Settings")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Preventive Maintenance") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")} />
            </span>
            <span className="menu-text">Preventive Maintenance</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Preventive Maintenance", "Checklists", "/checklist", "Checklist")}
              {renderMenuItem("Preventive Maintenance", "PpmSchedules", "/ppmSchedule", "Ppm Schedule")}
              {renderMenuItem("Preventive Maintenance", "Ppms", "/ppm", "Ppm")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Facility") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")} />
            </span>
            <span className="menu-text">Facility</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Facility", "Countrys", "/country", "Country")}
              {renderMenuItem("Facility", "Citys", "/city", "City")}
              {renderMenuItem("Facility", "Locations", "/location", "Location")}
              {renderMenuItem("Facility", "Buildings", "/building", "Building")}
              {renderMenuItem("Facility", "Floors", "/floor", "Floor")}
              {renderMenuItem("Facility", "Spots", "/spot", "Spot")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Customer") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-1.svg")} />
            </span>
            <span className="menu-text">Customer</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Customer", "Customers", "/customer", "Customer")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Contract") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">Contract</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Contract", "Contracts", "/contract", "Contract")}
              {renderMenuItem("Contract", "SubContracts", "/subContract", "Sub Contract")}
              {renderMenuItem("Contract", "OnetimeProjects", "/onetimeProject", "Onetime / Project")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Employee") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")} />
            </span>
            <span className="menu-text">Employee</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Employee", "Employees", "/employee", "Employee")}
              {renderMenuItem("Employee", "EmployeeDeployments", "/employeeDeployment", "Employee Deployment")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Human Resource") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">Human Resource</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Human Resource", "SalaryStructures", "/salaryStructure", "Salary Structure")}
              {renderMenuItem("Human Resource", "Payrolls", "/payroll", "Payroll")}
              {renderMenuItem("Human Resource", "LeaveRegistrys", "/leaveRegistry", "Leave Registry")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Attendance") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Attendance</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Attendance", "Attendances", "/attendance", "Daily Attendance")}
              {renderMenuItem("Attendance", "ArrivedatSites", "/arrivedatSite", "Arrived at Site")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Asset") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Polygon.svg")} />
            </span>
            <span className="menu-text">Asset</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Asset", "Assets", "/asset", "Asset")}
              {renderMenuItem("Asset", "AssetMigrations", "/assetMigration", "Asset Migration")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Inventory") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Inventory</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Inventory", "Materials", "/material", "Material")}
              {renderMenuItem("Inventory", "MaterialRequests", "/materialRequest", "Material Request")}
              {renderMenuItem("Inventory", "MaterialIssues", "/materialIssue", "Material Issue")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Procurement") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/PenAndRuller.svg")} />
            </span>
            <span className="menu-text">Procurement</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Procurement", "PurchaseRequests", "/purchaseRequest", "Purchase Request")}
              {renderMenuItem("Procurement", "Erps", "/erp", "Erp")}
              {renderMenuItem("Procurement", "PurchaseOrders", "/purchaseOrder", "Purchase Order")}
              {renderMenuItem("Procurement", "Grns", "/grn", "Grn")}
              {renderMenuItem("Procurement", "Invoices", "/invoice", "Invoice")}
              {renderMenuItem("Procurement", "Reports", "/report", "Report")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Property Management") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Property Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {renderMenuItem("Property", "Property", "/property", "Property")}
            </ul>
          </div>
        </li>
      )}
      {hasModulePermission(roles, permissions, "Reports") && (
        <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Reports</span>
            <i className="menu-arrow" />
          </NavLink>
        </li>
      )}
    </ul>
  );
}
