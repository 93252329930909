import { useSelector } from 'react-redux';

export const useRolesAndPermissions = () => {
    const { permissions, roles } = useSelector((state) => state.auth.user);
    return { permissions, roles };
};

export const hasModulePermission = (role, permissions, moduleName) => {
    const userRole = role;
    if (userRole === "0") {
        return true;
    }
    const module = permissions.modules.find((m) => m.name === moduleName);
    return module !== undefined;
};

export const hasFormPermission = (role, permissions, moduleName, formName) => {
    const userRole = role;
    if (userRole === "0") {
        return true;
    }
    const module = permissions.modules.find((m) => m.name === moduleName);
    if (module && module.forms) {
        const form = module.forms.find((f) => f.name === formName);
        return form !== undefined;
    }
    return false;
};

export const hasButtonPermission = (role, permissions, moduleName, formName, buttonName) => {
    const userRole = role;
    if (userRole === "0") {
        return true;
    }
    const module = permissions.modules.find((m) => m.name === moduleName);
    if (module) {
        const form = module.forms.find((f) => f.name === formName);
        if (form) {
            const button = form.buttons.find((b) => b.name === buttonName);
            return button !== undefined;
        }
    }
    return false;
};

export const checkPermission = (role, permissions, moduleName, formName, buttonName, permissionType) => {
    const userRole = role;
    if (userRole === "0") {
        return true;
    }
    const module = permissions.modules.find((m) => m.name === moduleName);
    if (module) {
        const form = module.forms.find((f) => f.name === formName);
        if (form) {
            if (form.buttons && form.buttons.length > 0) {
                const button = form.buttons.find((b) => b.name === buttonName);
                return button && button[`can_${permissionType}`] === 1;
            }
        }
    }
    return false;
};
