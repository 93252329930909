import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const LazyPageMaster = lazy(() => import("./modules/Custom/PagesMaster/PageMaster"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/:dynamicValue" render={(props) => <LazyPageMaster {...props} />} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}